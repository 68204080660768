.container {
    max-width: 100%;
    width: 90%;
    margin: auto !important;
}

* {
    font-family: "Poppins", sans-serif;
}

.input-box-border {
    border-style: solid;
    border-width: 1px;
    border-color: #D8E4FF;
}

input[type="text"] {
    border-color: #D8E4FF;
}

input[type="text"]:focus {
    outline: none;
    border-color: #D8E4FF;
}

.head-title {
    color: #060267;
}

.blog-button {
    background-color: #ECF2FF;
    font-size: 10px;
}

.blog-date-text {
    font-size: 10px;
    font-style: italic;
}

.blog-description-text {
    font-size: 12px;
}

.blog-description-text-overflow {
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blog-description-ellipse {
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header-date {
    font-size: 13px;
}

.mat-mdc-autocomplete-panel {
    background-color: white !important;
    border-radius: .5rem !important;
}

.mat-mdc-option.mdc-list-item:hover {
    background-color: rgb(236, 236, 236) !important;
}

.article-button {
    background-color: #3723DD;
    transition: background-color 0.3s
}

.article-button:hover {
    background-color: #291ba5;
}

.article-card {
    box-shadow: 0px 6px 5px 2px rgba(0, 0, 0, 0.1);
}

.header-box-shadow {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.blog-image-shadow {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.article-slider-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.circle-bullet-icon {
    color: #060267;
}

.highlight-text {
    background-color: #ECF2FF;
    font-weight: 600;
}

.mat-mdc-option .mdc-list-item__primary-text {
    width: 100% !important;
}

/* .article-row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var(--bs-gutter-x));
} */

/* html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */